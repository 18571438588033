<template>
  <div v-for="(singleblogs, key) in SingleBlog" :key="key">
    <section class="bloghead">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-12">
            <div class="d-flex">
              <p class="breadcrumbs">Blog</p>
              <RightOutlined
                :style="{ fontSize: '12px', color: '#0880e8' }"
                class="arrowsidebar"
              />
              <p class="breadcrumbs">Categorys</p>
            </div>
            <div class="blogheading">
              <div class="d-flex">
                <p class="blogtags">
                  <ICON_Tags :size="icon_size" :color="footer_color" />
                  <span style="padding: 0px 10px">{{
                    singleblogs.tag_line
                  }}</span>
                </p>
              </div>
              <h1 class="pt-4">{{ singleblogs.title }}</h1>
              <p class="d-flex">
                <span>Published on {{ singleblogs.date }}</span>
                <span class="dot"> . </span>
                <span class="readtime">5 min read</span>
              </p>
              <hr />
            </div>
          </div>
          <div class="col-lg-7 col-md-12">
            <div class="col-md-12">
              <Image :fpath="`${singleblogs.image}.jpg`" class="serviceimage" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 p-4 blogContent">
            <p v-html="$filters.markdown(singleblogs.content)"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- <BlogContent /> -->
  <RecentPosts />
</template>

<script>
// import BlogContent from "@/components/Blogposts/BlogContent.vue";
import RecentPosts from "@/components/Blogposts/RecentPosts.vue";
// import BlogHeader from "@/components/Blogposts/BlogHeader.vue";
import ICON_Tags from "@/assets/icons/Tags.vue";
import Image from "@/components/Image.vue";
import { RightOutlined } from "@ant-design/icons-vue";
import axios from "axios";
export default {
  name: "SingleBlogs",
  components: {
    // BlogContent,
    RecentPosts,
    RightOutlined,
    Image,
    ICON_Tags,
    // BlogHeader,
  },
  data() {
    return {
      footer_color: "black",
      icon_size: 20,
      lang: "en",
      SingleBlog: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/blogs/collections/airline_traffic_analytics/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.SingleBlog = [];
            return;
          } else if (data.data.length == 0) {
            this.SingleBlog = [];
            return;
          }
          this.SingleBlog = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.blogContent p {
  font-size: 18px;
  line-height: 1.7em;
}
.readtime {
  padding: 0px 10px;
}
span.dot {
  padding: 0px 0px 0px 10px;
}
.breadcrumbs {
  padding: 10px;
  font-size: 14px;
}
.arrowsidebar {
  position: relative;
  top: 15px;
}
.blogtags {
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #dadcdf;
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.bloghead {
  background: #f6f6f6;
  padding: 140px 0px 40px 0px;
}
@media (max-width: 998px) and (min-width: 0px) {
  .bloghead {
    padding: 30px;
  }
}
</style>
